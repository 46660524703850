body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

#container {
  width: 100%;
  height: 100%;
  display: block;
}
#veil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 1;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
}
